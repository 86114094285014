import React, { useState } from 'react'
import axios from "axios"
import styled from 'styled-components'
// components
import SEO from '../components/common/seo'
import Layout from '../components/common/layout'
import HeroHalf from '../components/common/HeroHalf'
import { Grid, Container, Box, Typography, TextField, Button } from '@material-ui/core'

const Contacto = () => {

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    });
    if (ok) {
      form.reset()
    }
  };
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: "post",
      url: "https://getform.io/f/1912212e-1cbb-4ba4-b700-61ad65657353",
      data: new FormData(form)
    })
      .then(r => {
        if (r.data.success === false) {
          handleServerResponse(false, "Error al enviar el mensaje. Intente nuevamente.", form)
        } else {
          handleServerResponse(true, "El mensaje fué enviado con éxito!", form)
        }
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }

  return (
    <Layout>
      <SEO title="Contacto" />
      <HeroHalf
        align="flex-end"
        text="Contacto"
        variant="h3"
      />
      <Box component="section" py={5}>
        <Container>
          <Grid container spacing={3} justify="center">
            <Grid item xs={12} sm={6}>
              <Typography align="left" gutterBottom component="p">
                Complete el formulario, le responderemos a la brevedad
          </Typography>
              <Form id="form" autoComplete="on" onSubmit={handleOnSubmit}>
                <TextField
                  required
                  id="name"
                  label="Nombre"
                  margin="normal"
                  fullWidth={true}
                  name="nombre"
                />
                <TextField
                  required
                  id="apellido"
                  label="Apellido"
                  margin="normal"
                  fullWidth={true}
                  name="apellido"
                />
                <TextField
                  required
                  id="email"
                  type="email"
                  label="Email"
                  margin="normal"
                  fullWidth={true}
                  name="email"
                />
                <TextField
                  id="telefono"
                  type="text"
                  label="Teléfono"
                  margin="normal"
                  fullWidth={true}
                  name="telefono"
                />
                <TextField
                  id="mensaje"
                  label="Mensaje"
                  multiline
                  rowsMax="4"
                  margin="normal"
                  fullWidth={true}
                  name="mensaje"
                />
                <Button type="submit" variant="contained" color="primary" disabled={serverState.submitting} style={{ marginTop: '1rem' }}>
                  Enviar
                </Button>
                {serverState.status &&
                  <Typography style={{ marginTop: '1rem' }} className={!serverState.status.ok && "errorMsg"} align="left" gutterBottom component="p">
                    {serverState.status.msg}
                  </Typography>
                }
              </Form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  )
}

export default Contacto

const Form = styled.form`
  .errorMsg{
    color: red;
  }
`